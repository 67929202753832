import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

function Add(){
	const navHistory = useNavigate();
	
	
	// using hooks
  const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
	  if (isMounted) {
		if (!AuthUtility.isLoggedIn()) {
		  AuthUtility.clearAuthData();
		  navHistory('/');
		} else {
		}
	  } else {
		setIsMounted(true);
	  }
	}, [isMounted, navHistory]);
	
	return(
		<div className="body-content bg-fff pt-70l-60s pb-170l-150s">
		
			<div className="panel large">
				<div className="grid-x bb1-333 pb-5">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-333 uppercase hide-for-small-only">Add</div>
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 uppercase show-for-small-only">Add</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default Add;