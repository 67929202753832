import React, {useState} from 'react';
import {Link, useNavigate, useLocation } from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../../components/frontend/auth/AuthUtility';

import OffCanvasNav from './OffCanvasNav';

import swal from 'sweetalert';

import logo from '../../assets/frontend/images/logo.png';

const OnCanvasHeader = () => {
	
	//logout should be its own component
	const navHistory = useNavigate();
	const location = useLocation();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);

	const logoutSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);

		const fetchDataLogout = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiDataLogout(response);
			} catch (error) {
			  handleApiErrorLogout(error);
			}
		};
	  
		fetchDataLogout();
	}
    function setApiDataLogout(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}

		setIsLoading(false);
	}
    function handleApiErrorLogout(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		setIsLoading(false);
		
		navHistory('/login');
    }

	var Navigation = '';

    if(!AuthUtility.isLoggedIn()){
	
		Navigation = (
			<nav className="nav right">
				<ul>
					<li className="menu1"><Link to="/login" className="font-patrick-hand font-x-large font-weight-600">Sign In</Link></li>
					<li className="menu1"><Link to="/sign_up" className="font-patrick-hand font-x-large font-weight-600">Sign Up</Link></li>
					{/*
					<li className="dropdown text-center">
						<Link to="/home" className="menu1 font-roboto font-small font-weight-500 uppercase">Home</Link>
						<ul>
							<li className="menu1"><Link to="/about" className="menu1 font-roboto font-small font-weight-500 uppercase">About</Link></li>
							<li className="menu1"><Link to="/help" className="menu1 font-roboto font-small font-weight-500 uppercase">Help</Link></li>
							<li className="menu1"><Link to="/contact" className="menu1 font-roboto font-small font-weight-500 uppercase">Contact</Link></li>
						</ul>
					</li>
					*/}
				</ul>
			</nav>
		);
	}

	return(
		<div className="sticky-top">
			<div className="sticky bg-fafafa bb1-ddd plr-20l-10sz">
				
				<div className="panel large">
				
					<div id="nav_bar" className="nav-bar">
						<div className="nav-bar-left"></div>
						<div className="nav-bar-center text-center">
							<Link to="/" className="hover-opacity-50">
								<img src={logo} alt="logo" width="141"/>
							</Link>
						</div>
						{location.pathname === '/search' && !AuthUtility.isLoggedIn() && (
						<div className="nav-bar-right hide-for-small-only">
							{Navigation}
						</div>
						)}
						{location.pathname === '/search' && !AuthUtility.isLoggedIn() && (
						<div className="nav-bar-right show-for-small-only">
							<OffCanvasNav />
						</div>
						)}
						{(location.pathname !== '/search' || AuthUtility.isLoggedIn()) &&  (
						<div className="nav-bar-right"></div>
						)}
					</div>
				</div>
			
			</div>
		</div>
	);
}

export default OnCanvasHeader;