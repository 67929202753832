import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

import $ from "jquery";
import swal from 'sweetalert';

import message_icon from '../../../assets/frontend/images/message_icon.png';

const error_style = 'font-patrick-hand font-medium font-weight-400 txt-333 bg-warning p-5';

function Register(){

	const navHistory = useNavigate();

	// using hooks
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
	const [registerInput, setRegister] = useState({
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		//confirmPassword: '',
		terms: '',
		role: 'member',
		sign_up: 'FSA',
		errorList: [],
		errorStyle: [],
	});
  
	//initial call to activate account
	useEffect(() => {
		
		if (isMounted) {

			if(AuthUtility.isLoggedIn()){
				//redirect to proper dashboard based on role
				if(localStorage.getItem('auth_role') === 'admin'){
					navHistory('/admin/dashboard');
				}else if(localStorage.getItem('auth_role')  === 'member'){
					navHistory('/member/dashboard');
				}else{
					navHistory('/');
				}
			}
			
		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted, navHistory]);

	const handleInput = (event) => {
		event.persist();

		setRegister({...registerInput, [event.target.name]: event.target.value });

		//remove the target error message no matter the new input, it will be validated on the server
		if (registerInput.errorList.hasOwnProperty(event.target.name)){
			delete registerInput.errorList[event.target.name];
			delete registerInput.errorStyle[event.target.name];
		}
	}

	const registerSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);

		//values sent to api
		const payload = {
			first_name: registerInput.first_name,
			last_name: registerInput.last_name,
			email: registerInput.email,
			password: registerInput.password,
			//confirmPassword: registerInput.confirmPassword,
			terms: registerInput.terms,
			role: registerInput.role,
			sign_up: registerInput.sign_up,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();
              const response = await makeRequestWithHeaders('post', '/api/register', payload, {});

              setApiData(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();
		
	}
    function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				$('.register-start').addClass('hide');
				$('.register-done').removeClass('hide');
				
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED

				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				//validation errors mapped to input fields
				setRegister({...registerInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}

    function handleApiError(error){
		console.log('[registerSubmit] error: ',error + ' back-end api call error');

		setIsLoading(false);
    }

	const resendVerificationEmail = (event) =>{
		event.preventDefault();
		
		setIsLoading(true);

		//values sent to api
		const payload = {
			first_name: registerInput.first_name,
			last_name: registerInput.last_name,
			email: registerInput.email,
			password: registerInput.password,
			role: registerInput.role,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();
              const response = await makeRequestWithHeaders('post', '/api/resend_verification_email', payload, {});

              setApiDataForResend(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();
	}
    function setApiDataForResend(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				//sweet alert on next page
				swal("Success",response.data.message,"success");
				
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
			}else{//more errors

				//sweet alert on next page
				swal("Success",response.data.message,"success");
			}
		}

		setIsLoading(false);
	}
	
	const showPrivacyPolicy = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Privacy Policy',
			text: 'Do you understand the privacy policy?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the privacy policy!'
		});
	}
	
	const showTermsConditions = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Terms & Conditions',
			text: 'Do you understand the terms & conditions?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the terms!'
		});
	}

	return(
		<div className="body-content scrollable bg-fff pt-50 pb-100">

			<div className="panel small">
				<div className="grid-x pt-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-patrick-hand font-xxx-large font-weight-600 txt-555 text-center uppercase">
							Sign Up
						</div>
					</div>
					<div className="large-12 medium-12 small-12 cell pt-20l-10s register-start">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-555 text-center uppercase">
							We're so glad you're' here !
						</div>
						<div className="hide-for-520px">
							<div className="font-patrick-hand font-x-large font-weight-400 txt-555 text-center pt-20l-10s">
								Create your <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> account to get started
							</div>
						</div>
						<div className="show-for-520px">
							<div className="font-patrick-hand font-large font-weight-400 txt-555 text-center pt-20l-10s">
								Create your <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> account to get started
							</div>
						</div>
					</div>
					<div className="large-12 medium-12 small-12 cell pt-20l-10s register-done hide">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-555 text-center uppercase">
							Check your email box !!
						</div>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-60l-20s register-start">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">

						<form onSubmit={registerSubmit}>
						<div className="grid-x">
							<div className="large-6 medium-6 small-12 cell text-left pr-5l-0s">
								<div className={registerInput.errorStyle.first_name}>{registerInput.errorList.first_name}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-user txt-555"></span></span>
									<input type="text" name="first_name" onChange={handleInput} value={registerInput.first_name} className="input-group-fieldz" placeholder="First Name" />
								</div>
							</div>
							<div className="large-6 medium-6 small-12 cell text-left pl-5l-0s">
								<div className={registerInput.errorStyle.last_name}>{registerInput.errorList.last_name}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-user txt-555"></span></span>
									<input type="text" name="last_name" onChange={handleInput} value={registerInput.last_name} className="input-group-field" placeholder="Last Name" />
								</div>
							</div>
							<div className="large-6 medium-6 small-12 cell text-left pr-5l-0s">
								<div className={registerInput.errorStyle.email}>{registerInput.errorList.email}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-envelope txt-555"></span></span>
									<input type="text" name="email" onChange={handleInput} value={registerInput.email} className="input-group-field" placeholder="Email" />
								</div>
							</div>
							<div className="large-6 medium-6 small-12 cell text-left pl-5l-0s">
								<div className={registerInput.errorStyle.password}>{registerInput.errorList.password}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-lock txt-555"></span></span>
									<input type="password" name="password" onChange={handleInput} value={registerInput.password} className="input-group-field" placeholder="Password" />
								</div>
							</div>
							<div className="large-6 medium-6 small-6 cell text-left pr-5l-0s">
								<div className={registerInput.errorStyle.terms}>{registerInput.errorList.terms}</div>
								<input type="checkbox" name="terms" id="terms_a" onChange={handleInput} value="1" />
								<label htmlFor="terms_a" className="checkbox-label"><span className="checkbox"></span><span className="message">&nbsp;&nbsp;&nbsp;<Link to="#" className="font-patrick-hand font-x-large font-weight-400 link-txt-777" onClick={showTermsConditions}>Terms<span className="hide-for-380px"> & Conditions</span></Link></span></label>
							</div>
							<div className="large-6 medium-6 small-6 cell clearfix">
								<button type="submit" className="button width-125px-100px right">Sign Up</button>
								{isLoading && 
								<span className="pr-10 right">
									<LoadingSpinner />
								</span>
								}
							</div>
						</div>
						</form>
						<div className="grid-x pt-20">
							<div className="large-12 medium-12 small-12 cell text-center">
							<Link to="/search" className="button large inverted width-100per"><img src={message_icon} width="25" alt="guest"/>&nbsp;&nbsp;&nbsp;<span className="font-x-large pt-5">Continue as Guest</span></Link>
							</div>
						</div>
		
					</div>
				</div>
			</div>
			
			<div className="panel small pt-60l-20s register-done hide">
				<div className="grid-x bg-fff b6-555 p-20">
					<div className="large-12 medium-12 small-12 cell text-center">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-555 justify">
							We sent an email link to complete your Sign Up.
							<br/><br/>
							<u>TIP:</u> if you do not see the email in a few minutes, check your "junk mail" folder or "spam" folder.
						</div>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-40l-20s register-done hide">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">
						<Link to="#" onClick={resendVerificationEmail} className="button large width-325px-100per">Resend Verification Email</Link>
					</div>
					{isLoading && 
					<div className="large-12 medium-12 small-12 cell text-center pt-20">
						<LoadingSpinner />
					</div>
					}
				</div>
			</div>

			<div className="panel small pt-40l-20s">
				<div className="grid-x bg-fff">
					<div className="large-12 medium-12 small-12 cell text-center">
						<span className="font-patrick-hand font-x-large font-weight-400 txt-555">Already a member?</span> <Link to="/login" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x">Sign In</Link>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-80l-40s">
				<div className="grid-x bg-fff">
					<div className="large-12 medium-12 small-12 cell text-center ">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-555 line-height-125per text-center">
							<div className="hide-for-small-onlyx">
							Continuing to use <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> <br className="show-for-small-only"/>means you accept our
							</div>
							<div className="pt-5">
							<Link to="#" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x" onClick={showTermsConditions}>Terms & Conditions</Link>
							&nbsp;and&nbsp;
							<Link to="#" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x" onClick={showPrivacyPolicy}>Privacy Policy</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default Register;