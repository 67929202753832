import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import SavedListsManager from './SavedListsManager';

import AxiosApiClient from '../utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

import $ from "jquery";
import swal from 'sweetalert';

import list_ul_icon from '../../assets/frontend/images/list_ul_icon.png';
import delete_icon from '../../assets/frontend/images/delete_icon.png';
import directions_curved_arrow_icon from '../../assets/frontend/images/directions_curved_arrow_icon.png';

function SavedLists(){
	const navHistory = useNavigate();
	
	// using hooks
	const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
	const [savedLists, setSavedLists] = useState([]);
	const [savedListsMessage, seSavedListsMessage] = useState('');

	useEffect(() => {
	
		if(!AuthUtility.isLoggedIn()){//user not authenticated locally so remove from local storage & force login
			AuthUtility.clearAuthData();
			navHistory('/');
		}

		if (isMounted) {
			console.log('[SavedLists - useEffect] mounted');
		} else {
			setIsMounted(true);
		}
	}, [isMounted, navHistory]);

	const handleSavedListsData = (savedListsFromWSLM) => {//properties coming from CheckListManager

		if(savedListsFromWSLM && savedListsFromWSLM.length > 0){
			setSavedLists(savedListsFromWSLM);
			seSavedListsMessage('');
		}else{
			setSavedLists([]);
			seSavedListsMessage('No Saved Lists Exist');
		}
		
		setIsLoading(false);
	};

	const handleSavedListsManagerOpen = (isSavedListsManagerOpen) => {
		if(isSavedListsManagerOpen){
		}else{
		}
	};
	
    // Showing delete all confirmation to users
    const handleDeleteSavedListConfirm = (saved_list_to_delete) => {

		if(!AuthUtility.isLoggedIn()){//user not authenticated locally so remove from local storage & force login
			AuthUtility.clearAuthData();
			swal("Info","Please login to delete "+saved_list_to_delete.name,"info");
			navHistory('/');
		}else{

			swal({
			name: 'Delete List',
			text: 'Do you want to delete ' + saved_list_to_delete.name + ' and all its saved locations?',
			icon: 'warning',
			buttons: {
				cancel: 'No, Cancel',
				confirm: 'Delete It!'
			}
			}).then((willDelete) => {
				if (willDelete) {
					$('#loading_spinner_'+saved_list_to_delete.random_id).removeClass('hide');
					deleteSavedList(saved_list_to_delete);
				}
			});
		}
    };
    
	function deleteSavedList(saved_list_to_delete){
			
		var random_id = saved_list_to_delete.random_id;
			
		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchDataDeleteSavedList = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('delete', '/api/delete_saved_list/'+random_id, null, {});

				setApiDataDeleteSavedList(response);
			} catch (error) {
				handleApiErrorDeleteSavedList(error);
			}
		};
	
		fetchDataDeleteSavedList();
		
	}

    function setApiDataDeleteSavedList(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('setApiDataDeleteSavedList: ',response.data.message);
						
				//set data
				if(response.data.saved_lists){

					setSavedLists(response.data.saved_lists);
				}
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('setApiDataDeleteSavedList error: ',response.data.message + ' back-end api call error');
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				swal("Warning",response.data.message,"warning");
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
			}else{//more errors
			}
		}

		$('.loading-spinner').addClass('hide');
	}

    function handleApiErrorDeleteSavedList(error){
		console.log('handleApiErrorDeleteSavedList error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		swal("Error",error,"error");
    }

	const handleCall = (phoneNumber) => {
	  if (phoneNumber) {
		window.location.href = `tel:${phoneNumber}`;
	  }
	};
	
	const handleDirections = (saved_location) => {
		if (saved_location.address) {
		  window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(saved_location.address)}`, '_blank');
		}
	};
	
    // Showing delete all confirmation to users
    const handleDeleteSavedLocationConfirm = (saved_location_to_delete) => {

		if(!AuthUtility.isLoggedIn()){//user not authenticated locally so remove from local storage & force login
			AuthUtility.clearAuthData();
			swal("Info","Please login to delete "+saved_location_to_delete.name,"info");
			navHistory('/');
		}else{

			swal({
			name: 'Delete Saved Location',
			text: 'Do you want to delete ' + saved_location_to_delete.name,
			icon: 'warning',
			buttons: {
				cancel: 'No, Cancel',
				confirm: 'Delete It!'
			}
			}).then((willDelete) => {
				if (willDelete) {
					$('#loading_spinner_'+saved_location_to_delete.random_id).removeClass('hide');
					deleteSavedLocation(saved_location_to_delete);
				}
			});
		}
    };
    
	function deleteSavedLocation(saved_location_to_delete){
			
		var random_id = saved_location_to_delete.random_id;
			
		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchDataDeleteSavedLocation = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('delete', '/api/delete_saved_location/'+random_id, null, {});

				setApiDataDeleteSavedLocation(response);
			} catch (error) {
				handleApiErrorDeleteSavedLocation(error);
			}
		};
	
		fetchDataDeleteSavedLocation();
		
	}

    function setApiDataDeleteSavedLocation(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('setApiDataDeleteSavedLocation: ',response.data.message);
						
				//delete saved location from the saved list in this component
				setSavedLists(prevSavedLists =>
					prevSavedLists.map(savedList  => ({
					  ...savedList ,
					  saved_locations: savedList.saved_locations.filter(saved_location => saved_location.random_id !== response.data.saved_location_random_id)
					}))
				);
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('setApiDataDeleteSavedLocation error: ',response.data.message + ' back-end api call error');
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				swal("Warning",response.data.message,"warning");
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
			}else{//more errors
			}
		}

		$('.loading-spinner').addClass('hide');
	}

    function handleApiErrorDeleteSavedLocation(error){
		console.log('handleApiErrorDeleteSavedLocation error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		swal("Error",error,"error");
    }

    // Toggle group classes
    const handleToggleSavedList = (saved_list_to_toggle) => {

		$('.saved-list').not('#saved_list_'+saved_list_to_toggle.random_id).removeClass('bg-pink').removeClass('b1-fs-gradient');
		$('.saved-list-saved-locations').not('#saved_list_saved_locations_'+saved_list_to_toggle.random_id).addClass('hide');
			
		/*
		//update the buton
		const savedListButton = $('.saved-list-button').not(`#saved_list_button_${saved_list_to_toggle.random_id}`);
		savedListButton.find('img').attr('src', chevron_down_icon);
		*/

		if($('#saved_list_saved_locations_'+saved_list_to_toggle.random_id).hasClass('hide')){
			$('#saved_list_saved_locations_'+saved_list_to_toggle.random_id).removeClass('hide');
			$('#saved_list_'+saved_list_to_toggle.random_id).addClass('bg-pink').addClass('b1-fs-gradient');
		}else{
			$('#saved_list_saved_locations_'+saved_list_to_toggle.random_id).addClass('hide');
			$('#saved_list_'+saved_list_to_toggle.random_id).removeClass('bg-pink').removeClass('b1-fs-gradient');
		}
		/*
		//update the buton
		const imgElement = $(`#saved_list_button_${saved_list_to_toggle.random_id} img`);
		const currentSrc = imgElement.attr('src');
		const newSrc = currentSrc === chevron_down_icon ? chevron_up_icon : chevron_down_icon;
		imgElement.attr('src', newSrc);
		*/

        // Remove focus from the button
        $('#saved_list_button_'+saved_list_to_toggle.random_id).blur();
    };

	return(
		<div className="body-content bg-fff pt-70l-60s pb-170l-150s">
		
			<div className="panel large">
				<div className="grid-x align-bottom bb2-333 pb-5">
					<div className="large-10 medium-10 small-10 cell align-self-bottom text-left">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-333 uppercase hide-for-small-only">Saved Lists</div>
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 uppercase show-for-small-only">Saved Lists</div>
					</div>
					<div className="large-2 medium-2 small-2 cell align-self-bottom text-right">
						<SavedListsManager onSavedListsData={handleSavedListsData} onSavedListsManagerOpen={handleSavedListsManagerOpen} />
					</div>
				</div>
				{isLoading && 
				<div className="grid-x align-bottom3">
					<div className="large-12 medium-12 small-12 cell text-center pt-10">
						<LoadingSpinner paddingClass="p-10"/>
					</div>
				</div>
				}
			</div>

	
			<div className="panel large">
				{savedLists.length === 0 ? ( 
				<div className="grid-x pt-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-patrick-hand font-large font-weight-400 txt-333 uppercase">{savedListsMessage}</div>
					</div>
				</div>
				): (
					savedLists.map((saved_list, i) => (
					<div id={"saved_list_"+saved_list.random_id} className="grid-x saved-list b1-ccc p-10l-5s mt-10">
						<div className="large-10 medium-10 small-9 cell text-left">
							<div className="clearfix">
								<span className="left">
								{saved_list.saved_locations && saved_list.saved_locations.length > 0 ? (
									<Link id={"saved_list_button_"+saved_list.random_id} onClick={() => handleToggleSavedList(saved_list)} className="button icon inverted saved-list-button">
									<img src={list_ul_icon} className="" width="40" alt="view saved list"/>
									</Link>
								) : (
									<Link className="button disabled icon inverted">
									<img src={list_ul_icon} className="" width="40" alt="view saved list"/>
									</Link>
								)}
								</span>
								<span className="font-patrick-hand font-xx-large font-weight-600 txt-333 pt-10 pl-10l-5s left hide-for-small-only">
									{saved_list.name}
									&nbsp;&nbsp;
									{saved_list.saved_locations && saved_list.saved_locations.length  > 0 ? '('+ saved_list.saved_locations.length +')' : '(0)'}
								</span>
								<span className="font-patrick-hand font-x-large font-weight-500 txt-333 pt-5 pl-10 left show-for-small-only">
									<div className="hide-for-480px">
									{saved_list.name}
									&nbsp;&nbsp;
									{saved_list.saved_locations && saved_list.saved_locations.length  > 0 ? '('+ saved_list.saved_locations.length +')' : '(0)'}
									</div>
									<div className="show-for-480px">
									<span className="font-patrick-hand font-large font-weight-500 txt-333 pt-5 pl-5"></span>
									{saved_list.name}
									&nbsp;&nbsp;
									{saved_list.saved_locations && saved_list.saved_locations.length  > 0 ? '('+ saved_list.saved_locations.length +')' : '(0)'}
									</div>
								</span>
							</div>
						</div>
						<div className="large-1 medium-1 small-1 cell text-right">
							<div id={"loading_spinner_"+saved_list.random_id} className="loading-spinner pr-10 hide">
							<LoadingSpinner paddingClass="none" sizeClass="small"/>
							</div>
						</div>
						<div className="large-1 medium-1 small-2 cell text-right">
							<Link onClick={() => handleDeleteSavedListConfirm(saved_list)} onTouchEnd={() => handleDeleteSavedListConfirm(saved_list)} className="button icon inverted">
								<img src={delete_icon} className="" width="25" alt="delete saved list"/>
							</Link>
						</div>

						{!saved_list.saved_locations || saved_list.saved_locations.length === 0 ? (
						<></>
						) : (
						<div id={"saved_list_saved_locations_"+saved_list.random_id} className="large-12 medium-12 small-12 cell saved-list-saved-locations bg-light-pink scrollable-250px bt2-333 mt-10 hide">
						{saved_list.saved_locations.map((saved_location, j) => (
							<div className="bb1-aaa  ptb-10">
                    			<div className="clearfix">
									<div className="pr-10 text-left left">
										<Link onClick={() => handleDirections(saved_location)} className="button icon inverted">
											<img src={directions_curved_arrow_icon} className="" width="50" alt="saved location directions"/>
										</Link>
									</div>
									<div className="font-patrick-hand font-x-large font-weight-500 txt-333 pr-10 text-left left hide-for-small-only">
										{saved_location.name}
										<div className="pt-5 text-left">
											{saved_location.address}
										</div>
										{saved_location.phone &&
										<div className="pt-5 text-left">
											<Link to="" onClick={() => handleCall(saved_location.phone)}>Call {saved_location.phone}</Link>
										</div>
										}
									</div>
									<div className="font-patrick-hand font-large font-weight-500 txt-333 pr-10 text-left left show-for-small-only">
										<div className="hide-for-480px">
										{saved_location.name}
										<div className="pt-5 text-left">
											{saved_location.address}
										</div>
										{saved_location.phone &&
										<div className="pt-5 text-left">
											<Link to="" onClick={() => handleCall(saved_location.phone)}>Call {saved_location.phone}</Link>
										</div>
										}
										</div>
										<div className="show-for-480px">
										{saved_location.name}
										<div className="font-patrick-hand font-medium font-weight-500 txt-333 pt-5 pr-5 text-left">
											{saved_location.address}
										</div>
										{saved_location.phone &&
										<div className="font-patrick-hand font-medium font-weight-500 txt-333 pt-5 pr-5 text-left">
											<Link to="" onClick={() => handleCall(saved_location.phone)}>Call {saved_location.phone}</Link>
										</div>
										}
										</div>
									</div>
									<div className="right">
										<Link onClick={() => handleDeleteSavedLocationConfirm(saved_location)} onTouchEnd={() => handleDeleteSavedLocationConfirm(saved_location)} className="button icon inverted">
											<img src={delete_icon} className="" width="25" alt="delete saved location"/>
										</Link>
									</div>
									<div className="pr-10 right">
										<div id={"loading_spinner_"+saved_location.random_id} className="loading-spinner hide">
										<LoadingSpinner paddingClass="none" sizeClass="small"/>
										</div>
									</div>
								</div>
								{(saved_location.phone || saved_location.wifi_speed || saved_location.noise_level || saved_location.free_internet) && 
								<>
								<div className="clearfix pt-10 pl-50l-0s text-left">
									{saved_location.wifi_speed &&
									<div className="left">
									<span className="font-patrick-hand font-large font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 ptb-0 plr-5 mr-10 hide-for-small-only">wifi {saved_location.wifi_speed}</span>
									<span className="font-patrick-hand font-medium font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 ptb-0 plr-5 mr-10 show-for-small-only">wifi {saved_location.wifi_speed}</span>
									</div>
									}
									{saved_location.noise_level &&
									<div className="left">
									<span className="font-patrick-hand font-large font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 hide-for-small-only">noise {saved_location.noise_level}</span>
									<span className="font-patrick-hand font-medium font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 show-for-small-only">noise {saved_location.noise_level}</span>
									</div>
									}
									{saved_location.free_internet && saved_location.free_internet === 'yes' ? (
									<div className="left">
									<span className="font-patrick-hand font-large font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 hide-for-small-only">free internet</span>
									<span className="font-patrick-hand font-medium font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 show-for-small-only">free internet</span>
									</div>
									) : (
										<div className="left">
										<span className="font-patrick-hand font-large font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 hide-for-small-only">no internet free</span>
										<span className="font-patrick-hand font-medium font-weight-500 txt-000 bg-very-light-pink b1-aaa br-5 plr-5 mr-10 show-for-small-only">no internet free</span>
										</div>
									)
									}
								</div>
								</>
								}
							</div>
						))}
						</div>
						)}
					</div>
					))
				)}
			</div>

		</div>
	);
}

export default SavedLists;