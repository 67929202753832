import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate, useParams} from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

import axios from 'axios';
import swal from 'sweetalert';

function ActivateAccount(){

	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [isActivated, setIsActivated] = useState(false);
	const [navPath, setNavPath] = useState('');
	
	const { id } = useParams();
  
	//initial call to activate account
	useEffect(() => {
		
		setIsLoading(true);
		
		if (isMounted) {
	
			//values sent to api
			const payload = {
				random_id: id,
			}

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
				  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				  await getBearerToken();
				  const response = await makeRequestWithHeaders('put', '/api/activate_account/'+id, null, {});
	
				  setApiData(response);
				} catch (error) {
				  handleApiError(error);
				}
			};
		  
			fetchData();
			
		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted, id, navHistory]);

    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
			
				AuthUtility.setAuthData(response.data.auth_first_name, response.data.auth_last_name, 
				response.data.auth_email, response.data.auth_token, response.data.auth_role,
				null, null);

				setIsActivated(true);
				
				swal("Success",response.data.message,"success");
				if(localStorage.getItem('auth_role') === 'member'){
					setNavPath('/member/account');
					navHistory('/member/account');
				}else if(localStorage.getItem('auth_role') === 'admin'){
					setNavPath('/admin/dashboard');
					navHistory('/admin/dashboard');
				}
					
			}else if(response.data.status === 401 || response.data.status === 404){//HTTP_UNAUTHORIZED OR HTTP_NOT_FOUND
			
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
			
				setIsLoading(false);
				swal("Warning",response.data.message + ' Can not activate account. Please click on the link in your email after registering.',"warning");
				navHistory('/login');

			}else{//more errors

			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		//csrf-cookie is outdated
		console.log('[ActivateAccount] error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
			
		setIsLoading(false);
		swal("Error",error,"error");
		navHistory('/login');
    }

	return(
		<div className="body-content scrollable bg-fff ">
		
			<div className="panel large ptb-20">

				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 bb1-333 letter-spacing-1px uppercase pb-5">Activate Account</div>
					</div>
				</div>
			</div>
		
			<div className="panel large pt-20">
				<div className="grid-x">
				
					{isLoading && 
					<div className="large-12 medium-12 small-12 cell text-center">
					<LoadingSpinner paddingClass="p-20l-10s" />
					</div>
					}
					{isActivated && 
					<div className="large-12 medium-12 small-12 cell text-left">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Name:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_first_name')+" "+localStorage.getItem('auth_last_name')}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Email:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_email')}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Role:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_role')}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Status:</td><td className="font-raleway font-weight-600 pb-20">Activated</td></tr>
							</tbody>
						</table>
					</div>
					}
				</div>
			</div>
			
		</div>
	);
}

export default ActivateAccount;