import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

import axios from 'axios';
import swal from 'sweetalert';

const error_style = 'font-patrick-hand font-small font-weight-500 txt-000 bg-light-red p-5';

function ForgotPassword(){
	
	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
	const [forgotPasswordInput, setForgotPassword] = useState({
		email: '',
		errorList: [],
		errorStyle: [],
	});
	
	const handleInput = (event) => {
		event.persist();
		
		setForgotPassword({...forgotPasswordInput, [event.target.name]: event.target.value });
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (forgotPasswordInput.errorList.hasOwnProperty(event.target.name)){
			delete forgotPasswordInput.errorList[event.target.name];
			delete forgotPasswordInput.errorStyle[event.target.name];
		}
	}

	const forgotPasswordSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);
			
		//values sent to api
		const payload = {
			email: forgotPasswordInput.email,
		}
		
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.post('/api/forgot_password', payload).then(response =>{
				if(response.data.status === 200){//HTTP_OK

                	localStorage.removeItem('password');
                	
                    swal("Success",response.data.message,"success");
                    navHistory('/login');
					
                }else if(response.data.status === 404){//HTTP_NOT_FOUND
					setForgotPassword({...forgotPasswordInput, errorList: {email: [response.data.message]}, errorStyle: {email: [error_style]}});
				}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
					var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
					Object.keys(errorStyleTemp).map((key) => (
						errorStyleTemp[key] = error_style
					));
					
					//validation errors mapped to input fields
					setForgotPassword({...forgotPasswordInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
                }else{//more errors
				}
		
				setIsLoading(false);
				
			}).catch(function (error) {
				console.log('[forgotPasswordSubmit] error: ',error + ' back-end api call error');
		
				setIsLoading(false);
					
			});
		}).catch(function (error) {
			console.log('[forgotPasswordSubmit] error: ',error + ' csrf-cookie is outdated');
		            
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
		
			setIsLoading(false);
			swal("Error",error,"error");
			navHistory('/forgot_password');
		});
	}
	
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel medium pt-20">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
						<div className="font-patrick-hand font-xxl-large-mr font-weight-700 txt-333 underline uppercase">Password Assistance</div>
						<div className="font-patrick-hand font-medium font-weight-500 txt-222 text-center p-10">Not registered with us yet? <Link to="/register" className="font-patrick-hand font-medium font-weight-600">Sign Up</Link></div>
						
						<div id="sign_in_info_and_error" className="font-patrick-hand font-medium text-center ptb-10 hide"></div>
		
						<form onSubmit={forgotPasswordSubmit}>
							<div className="grid-x">
								<div className="large-12 medium-12 small-12 cell text-left">
									<div className={forgotPasswordInput.errorStyle.email}>{forgotPasswordInput.errorList.email}</div>
									<div className="input-group">
										<span className="input-group-label"><span className="fas fa-envelope txt-555"></span></span>
										<input type="text" name="email" onChange={handleInput}  value={forgotPasswordInput.email} className="input-group-field" placeholder="name@example.com" />
									</div>
								</div>
								<div className="large-12 medium-12 small-12 cell clearfix">
									<button type="submit" className="button width-125px-100px right">Submit</button>
									{isLoading && 
									<span className="pr-10 right">
										<LoadingSpinner />
									</span>
									}
								</div>
							</div>
							<div className="grid-x pt-20">
								<div className="large-12 medium-12 small-12 cell text-center bg-fafafa p-20 br-5">
									<Link to="/login" className="font-patrick-hand font-medium font-weight-600">Have an account? Sign In!</Link>
								</div>
							</div>
						</form>
		
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;