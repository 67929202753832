import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";

import AxiosApiClient from '../utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

import swal from 'sweetalert';

import plus_icon_black from '../../assets/frontend/images/plus_icon_black.png';
import close_icon_black from '../../assets/frontend/images/close_icon_black.png';

const SavedListsManager = ({ onSavedListsData, onSavedListsManagerOpen }) => {//sends forecast data and boolean for opening/closing the Lists finder
	
	const navHistory = useNavigate();
	
	//check if clicked target is not within the offcanvasnav
	const savedListsIconRef = useRef();
	const savedListsManagerRef = useRef();
	const closeSavedListsManagerRef = useRef();
	
	//using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
	const [isSavedListsManagerOpen, setIsSavedListsManagerOpen] = useState(false);
	const [newSavedList, setNewSavedList] = useState({
		user_id: -1,
		name: '',
		status: '',
		ranom_id: '',
		info: '',
	});
	const [savedLists, setSavedLists] = useState([]);
	
	//handles click outside slide out
	useEffect(() => {
		const handleClickOutside = (event) => {
			
			// add event listener to close menu when clicked outside		
			if (savedListsManagerRef.current && !savedListsManagerRef.current.contains(event.target)) {
				setNewSavedList({...newSavedList, info: ''});

				onSavedListsManagerOpen(false);
				setIsSavedListsManagerOpen(false);
			}
			
			//open nav with mobile icon click which is in the div id=navigation
			if (!isSavedListsManagerOpen && savedListsIconRef.current && savedListsIconRef.current.contains(event.target) && savedListsIconRef.current.id === 'Lists_icon') {
				onSavedListsManagerOpen(true);
				setIsSavedListsManagerOpen(true);
			}
		}
		
		document.addEventListener("mousedown", handleClickOutside)
			
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isSavedListsManagerOpen, onSavedListsManagerOpen]);
  
	//initial call for saved list
	useEffect(() => {
		
		if (isMounted) {

			if(!AuthUtility.isLoggedIn()){//user not authenticated locally so remove from local storage & force login
				AuthUtility.clearAuthData();
				swal("Info","Please login to load your saved lists", "info");
				navHistory('/');
			}else{
			
				console.log('[SavedListsManager - useEffect] mounted');
				
				var auth_api = 'phpLaravel';
				if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
					auth_api = localStorage.getItem('auth_api');
				}

				const fetchDataSavedLists = async () => {
					try {
						const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
						await getBearerToken();
						const response = await makeRequestWithHeaders('get', '/api/read_saved_lists', null, {});

						setApiDataSavedLists(response);
					} catch (error) {
						handleApiErrorSavedLists(error);
					}
				};
			
				fetchDataSavedLists();
			}
			
		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted]);

    function setApiDataSavedLists(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('setApiDataSavedLists: ',response.data.message);
						
				//set data
				if(response.data.saved_lists){
					setSavedLists(response.data.saved_lists);
				}
				setIsSavedListsManagerOpen(false);
				setNewSavedList({...newSavedList, user_id: -1, name: '', status: '', random_id: ''});
					
				//pass data to SavedLists
				onSavedListsManagerOpen(false);
				onSavedListsData(response.data.saved_lists);
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('setApiDataSavedLists error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");

			} else if (response.data.status === 404 || response.data.status === 422) {//HTTP_NOT_FOUND or HTTP_UNPROCESSABLE_ENTITY
			  console.log('setApiDataSavedLists error: ', response.data.message + ' back-end api call error');
	  
			  swal("Warning", response.data.message, "warning");

			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
			}else{//more errors
			}
		}
	}

    function handleApiErrorSavedLists(error){
		console.log('setApiDataSavedLists error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		swal("Error",error,"error");
    }

	const toggleSavedListsManager = (event) => {
		event.preventDefault();

		if(isSavedListsManagerOpen){
			setNewSavedList({...newSavedList, info: ''});
		}
		
		onSavedListsManagerOpen(!isSavedListsManagerOpen);
		
		setIsSavedListsManagerOpen(!isSavedListsManagerOpen);
	}

    const handleInputChange = (event) => {
		//event.stopPropagation();
		event.persist();

        const { name, value } = event.target;
        
		//set data
		setNewSavedList({...newSavedList, name: value, info: ''});
    };
    /*
    const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission
			
			if (event.target.name === 'saved_list_name') {
		        const { name, value } = event.target;
				
				handleSaveList(event);
			}
		}
	};
	*/
	
    // Function to handle save
    const saveListSubmit = (event) => {
		event.preventDefault();

		if(!AuthUtility.isLoggedIn()){//user not authenticated locally so remove from local storage & force login
			AuthUtility.clearAuthData();
			swal("Info","Please login to load create a list", "info");
			navHistory('/');
		}else{
			if(newSavedList.name){
				saveList(event);
			}else{
				setNewSavedList({...newSavedList, info: 'Error - Empty List Title'});
				setIsLoading(false);
			}
		}
    };
    
	function saveList(event){
		event.preventDefault();
		
		setIsLoading(true);
		setIsSaving(true);
			
		var payload = {
			saved_list_name: newSavedList.name,
		}
			
		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchDataNewSavedList = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('post', '/api/save_list', payload, {});

				setApiDataNewSavedList(response);
			} catch (error) {
				handleApiErrorNewSavedList(error);
			}
		};
	
		fetchDataNewSavedList();
		
	}

    function setApiDataNewSavedList(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('setApiDataNewSavedList: ',response.data.message);
						
				//set data
				if(response.data.saved_lists){
					setSavedLists(response.data.saved_lists);
				}

				setIsSavedListsManagerOpen(false);
				setNewSavedList({...newSavedList, user_id: -1, name: '', status: '', random_id: '', info: ''});
					
				//pass data to SavedLists Component
				onSavedListsManagerOpen(false);
				onSavedListsData(response.data.saved_lists);
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('loginSubmit error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");

			} else if (response.data.status === 404 || response.data.status === 422) {//HTTP_NOT_FOUND or HTTP_UNPROCESSABLE_ENTITY
			  console.log('setApiDataSavedLists error: ', response.data.message + ' back-end api call error');
	  
			  swal("Warning", response.data.message, "warning");

			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED

				//validation errors mapped to input fields
				//setSavedListInput({ ...savedListInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
				setNewSavedList({...newSavedList, info: response.data.validation_errors});
			}else{//more errors
			}

			setIsLoading(false);
			setIsSaving(false);
		}
	}

    function handleApiErrorNewSavedList(error){
		console.log('setApiDataNewSavedList error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		swal("Error",error,"error");
    }
	
	
	return(
		<OffCanvas width={270} transitionDuration={300} effect={"parallax"} isMenuOpened={isSavedListsManagerOpen} position={"right"}>
			<OffCanvasBody>
				<div id="saved_list_icon" className="p-0 m-0" ref={savedListsIconRef}>
					<Link to="#" className="button icon inverted" onClick={toggleSavedListsManager} onTouchEnd={toggleSavedListsManager}>
						<img src={plus_icon_black} width="30" alt="New List"/>
					</Link>
				</div>
			</OffCanvasBody>
			<OffCanvasMenu >
				<div id="list_finder" className="z-index-2100 bg-fafafa bl1-ccc" style={{height:"2000px", overflow:"hidden"}} ref={savedListsManagerRef}>
					<div className="p-10 pr-20 mt-60X">
						<Link to="#" className="button icon inverted text-center right" onClick={toggleSavedListsManager} onTouchEnd={toggleSavedListsManager} ref={closeSavedListsManagerRef}><img src={close_icon_black} className="" width="40" alt="add new city"/></Link>
					</div>
					<div className="bg-fff b1-ccc p-10 m-10 mt-40">
						<div className="font-patrick-hand font-large font-weight-400 txt-555 uppercase text-left">Create New List</div>
						<div className="clearfix mt-10 ">
							
							<form onSubmit={saveListSubmit} >
							<span className="left"><input type="text" className="small width-175px" value={newSavedList.name} name="saved_list_name" onChange={handleInputChange} placeholder="Coffee Shop List" /></span>
							<span className="right">
								{isSaving ? (
								<span className="button icon disabled">
									<img src={plus_icon_black} width="45" alt="Save New List"/>
								</span>
								) : (
								<button className="button icon inverted">
									<img src={plus_icon_black} width="45" alt="Save List"/>
								</button>
								)}
							</span>
							</form>
						</div>
					</div>
					{isLoading && 
					<div className="text-center">
						<LoadingSpinner paddingClass="none" />
					</div>
					}
					<div className="font-patrick-hand font-large font-weight-400 txt-red p-10 pl-20 text-left">{newSavedList.info}</div>
					
				</div>
			</OffCanvasMenu>
		</OffCanvas>
	);
	
}

export default SavedListsManager;

function shortenString(str) {
  if (str.length > 22) {
    return str.substring(0, 22);
  } else {
    return str;
  }
}
