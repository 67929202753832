import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import SingleSignOn from './SingleSignOn';

import swal from 'sweetalert';

import message_icon from '../../../assets/frontend/images/message_icon.png';

const error_style = 'font-patrick-hand font-small font-weight-500 txt-000 bg-light-red p-5';

function SignUp(){
	
	const showPrivacyPolicy = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Privacy Policy',
			text: 'Do you understand the privacy policy?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the privacy policy!'
		});
	}
	
	const showTermsConditions = (event) => {
		event.preventDefault();
	
		//Type appropriate comment here, and begin script below
		swal({
			title: 'Terms & Conditions',
			text: 'Do you understand the terms & conditions?',
			html: true,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes, I understand the terms!'
		});
	}

	return(
		<div className="body-content scrollable bg-fff pt-50 pb-100">

			<div className="panel small">
				<div className="grid-x pt-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-patrick-hand font-xxx-large font-weight-600 txt-555 text-center uppercase">
							Sign Up
						</div>
					</div>
					<div className="large-12 medium-12 small-12 cell pt-20l-10s">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-555 text-center uppercase">
							We're so glad you're' here !
						</div>
						<div className="hide-for-520px">
							<div className="font-patrick-hand font-x-large font-weight-400 txt-555 text-center pt-20l-10s">
								Create your <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> account to get started
							</div>
						</div>
						<div className="show-for-520px">
							<div className="font-patrick-hand font-large font-weight-400 txt-555 text-center pt-20l-10s">
								Create your <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> account to get started
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-40l-20s">
				<div className="grid-x bg-fff">
					<div className="large-12 medium-12 small-12 cell text-center">
						<Link to="/register" className="button large inverted width-325px-100per"><img src={message_icon} width="25" alt="guest"/>&nbsp;&nbsp;&nbsp;<span className="font-x-large pt-5">Continue with Email</span></Link>
					</div>
				</div>
			</div>

			<SingleSignOn />

			<div className="panel small pt-40l-20s">
				<div className="grid-x bg-fff">
					<div className="large-12 medium-12 small-12 cell text-center">
						<span className="font-patrick-hand font-x-large font-weight-400 txt-555">Already a member?</span> <Link to="/login" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x">Sign In</Link>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-80l-40s">
				<div className="grid-x bg-fff">
					<div className="large-12 medium-12 small-12 cell text-center ">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-555 line-height-125per text-center">
							<div className="hide-for-small-onlyx">
							Continuing to use <span className="font-weight-600 letter-spacing-1px">FlexSpace</span> <br className="show-for-small-only"/>means you accept our
							</div>
							<div className="pt-5">
							<Link to="#" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x" onClick={showTermsConditions}>Terms & Conditions</Link>
							&nbsp;and&nbsp;
							<Link to="#" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x" onClick={showPrivacyPolicy}>Privacy Policy</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default SignUp;