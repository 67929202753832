import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

import swal from 'sweetalert';

import edit_icon from '../../assets/frontend/images/edit_icon.png';
import arrow_right_icon from '../../assets/frontend/images/arrow_right_icon.png';

function Account(){
	
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

	// Initial call for user list items
	useEffect(() => {
	
		if(!AuthUtility.isLoggedIn()){
				
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
				
			navHistory('/');
		}
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Account - useEffect] mounted');
			/*
			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('get', '/api/subscription_info', null, {});

					setApiData(response);
				} catch (error) {
					handleApiError(error);
				}
			};
		
			fetchData();
			console.log('Account Subscription amount:', subscriptionInfo.payment_amount);
			*/
			
		}else {
			setIsMounted(true);
		}
		
	}, [isMounted]);

	const logoutSubmit = (event) => {
		event.preventDefault();

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiDataLogout(response);
			} catch (error) {
			  handleApiErrorLogout(error);
			}
		};
	  
		fetchData();
	}
    function setApiDataLogout(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}
	}
    function handleApiErrorLogout(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		navHistory('/login');
    }
    
	return(
		<div className="body-content bg-fff pt-70l-60s pb-170l-150s">
		
			<div className="panel large">
				<div className="grid-x bb1-333 pb-5">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-333 uppercase hide-for-small-only">Account</div>
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 uppercase show-for-small-only">Account</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 uppercase underline hide-for-small-only">Profile</div>
						<div className="font-patrick-hand font-large font-weight-400 txt-333 uppercase underline show-for-small-only">Profile</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link to="../profile" className="button icon inverted"><img src={edit_icon} alt="profile"/></Link>
					</div>

					<div className="large-12 medium-12 small-12 cell text-left pt-10">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-patrick-hand font-large font-weight-400 txt-333 width-100px pb-10">Name:</td><td className="font-patrick-hand font-large font-weight-500 txt-333 pb-10">{localStorage.getItem('auth_first_name') + ' ' + localStorage.getItem('auth_last_name')}</td></tr>
							<tr><td className="font-patrick-hand font-large font-weight-400 txt-333 width-100px pb-10">Email:</td><td className="font-patrick-hand font-large font-weight-500 txt-333 pb-10">{localStorage.getItem('auth_email')}</td></tr>
							<tr><td className="font-patrick-hand font-large font-weight-400 txt-333 width-100px pb-10">Role:</td><td className="font-patrick-hand font-large font-weight-500 txt-333 pb-10">{localStorage.getItem('auth_role')}</td></tr>
							<tr><td className="font-patrick-hand font-large font-weight-400 txt-333 width-100px">Password:</td><td className="font-patrick-hand font-large font-weight-500 txt-333 ">********</td></tr>
							</tbody>
						</table>
					</div>
					
				</div>
			</div>
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-patrick-hand font-x-large font-weight-400 txt-333 uppercase underline hide-for-small-only">Logout</div>
						<div className="font-patrick-hand font-large font-weight-400 txt-333 uppercase underline show-for-small-only">Logout</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link onClick={logoutSubmit} className="button icon inverted"><img src={arrow_right_icon} alt="logout"/></Link>
					</div>
					
				</div>
			</div>

		</div>
	);
}

export default Account;