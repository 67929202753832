import React from 'react';
import {Link} from 'react-router-dom';

import AuthUtility from './auth/AuthUtility';

import logo from '../../assets/frontend/images/logo.png';
import message_icon from '../../assets/frontend/images/message_icon.png';

function Home(){


	var LoginOrDashboardButton = <Link to="/login" className="button large width-225px-100per">Sign In</Link>;

    if(AuthUtility.isLoggedIn()){
		LoginOrDashboardButton = <Link to="/member/dashboard" className="button large width-225px-100per">Dashboard</Link>
	}

	return(
		<div className="body-content scrollable bg-fff">

			<div className="panel small">
				<div className="grid-x pt-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
						<img src={logo} alt="logo" width="auto"/>
					</div>
				</div>
			</div>

			<div className="panel small">
				<div className="grid-x pt-60l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-555 text-center uppercase bg-fff b6-555 p-20l-10s">
							Find a place to
							<div className="pt-5"></div>
							work/study remotely
						</div>
					</div>
				</div>
			</div>

			<div className="panel small">
				<div className="grid-x pt-60l-20s">
					<div className="large-12 medium-12 small-12 cell pb-20">
						<Link to="/" className="font-patrick-hand font-x-large font-weight-400 link-txt-777">Discover <span className="hide-for-480px">all kinds of </span>places to work/study remotely</Link>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">cafe</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">cafe</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">library</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">library</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">museum</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">museum</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">hotel</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">hotel</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center pt-20l-10s">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">park</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">park</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center pt-20l-10s">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">airport</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">airport</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center pt-20l-10s">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">lounge</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">lounge</span></span>
					</div>
					<div className="large-3 medium-3 small-3 cell text-center pt-20l-10s">
						<span className="hide-for-520px"><span className="button medium p-5 inverted no-hover box-shadow">coworking</span></span>
						<span className="show-for-520px"><span className="button small p-5 inverted no-hover box-shadow">coworking</span></span>
					</div>
				</div>
			</div>

			{!AuthUtility.isLoggedIn() ? (
			<div className="panel small pt-60l-20s">
				<div className="grid-x">
					<div className="large-6 medium-6 small-12 cell text-left-center-on-small">
						<Link to="/login" className="button large width-225px-100per">Sign In</Link>
					</div>
					<div className="show-for-small-only"><span className="pt-20"></span>></div>
					<div className="large-6 medium-6 small-12 cell text-right-center-on-small">
						<Link to="/search" className="button large inverted width-225px-100per"><img src={message_icon} width="20" alt="guest"/>&nbsp;&nbsp;&nbsp;Continue as Guest</Link>
					</div>
					<div className="large-12 medium-12 small-12 cell pt-20">
						<span className="font-patrick-hand font-x-large font-weight-400 txt-555">No Account?  <Link to="/sign_up" className="font-patrick-hand font-x-large font-weight-400 link-txt-777x">Sign Up</Link></span>
					</div>
				</div>
			</div>
			) : (
				<div className="panel small pt-100l-40s">
					<div className="grid-x">
						<div className="large-12 medium-12 small-12 cell text-center">
							<Link to="/member/account" className="button large width-225px-100per">Welcome to FLEXSPACE!</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Home;