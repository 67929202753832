import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

import swal from 'sweetalert';

import logo from '../../../assets/frontend/images/logo.png';
import message_icon from '../../../assets/frontend/images/message_icon.png';

const error_style = 'font-Roboto font-small font-weight-500 txt-000 bg-warning p-5';

function Login(){
	
	const navHistory = useNavigate();

	// using hooks
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
	const [loginInput, setLogin] = useState({
		email: localStorage.getItem('auth_email') ? localStorage.getItem('auth_email') : '',
		password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
		errorList: [],
		errorStyle: [],
	});

	const [isChecked, setIsChecked] = useState(localStorage.getItem('remember_me') && localStorage.getItem('remember_me') === 'true' ? true : false);
  
	//initial call to activate account
	useEffect(() => {
		
		if (isMounted) {

			if(AuthUtility.isLoggedIn()){
				//redirect to proper dashboard based on role
				if(localStorage.getItem('auth_role') === 'admin'){
					navHistory('/admin/dashboard');
				}else if(localStorage.getItem('auth_role')  === 'member'){
					navHistory('/member/dashboard');
				}else{
					navHistory('/');
				}
			}
			
		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted, navHistory]);
	
	const handleInput = (event) => {
		event.persist();
		
		if(event.target.name !== 'rememberMe'){
			setLogin({...loginInput, [event.target.name]: event.target.value });
		}
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (loginInput.errorList.hasOwnProperty(event.target.name)){
			delete loginInput.errorList[event.target.name];
			delete loginInput.errorStyle[event.target.name];
		}
	}

	const loginSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);
			
		//values sent to api
		const payload = {
			email: loginInput.email,
			password: loginInput.password,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();
              const response = await makeRequestWithHeaders('post', '/api/login', payload, {});

              setApiData(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();

	}
    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('loginSubmit: ',response.data.message);
			
				AuthUtility.setAuthData(response.data.auth_first_name, response.data.auth_last_name, 
				response.data.auth_email, response.data.auth_token, response.data.auth_role,
				loginInput.password, isChecked);
				
				//redirect to proper dashboard based on role
				if(response.data.auth_role === 'admin'){
					navHistory('/admin/dashboard');
				}else if(response.data.auth_role === 'member'){
					navHistory('/member/account');
				}else{
					navHistory('/');
				}
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('loginSubmit error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");

			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				//validation errors mapped to input fields
				setLogin({...loginInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		console.log('loginSubmit error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
					
		setIsLoading(false);

		swal("Error",error,"error");
    }

	const handleCheckboxChange = (event) => {
	    const { checked } = event.target;
	    setIsChecked(checked);
	    localStorage.setItem('remember_me', checked);
	};
	
	return(
		<div className="body-content scrollable bg-fff pb-100">

			<div className="panel small">
				<div className="grid-x pt-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-center">
						<Link to="/" className="hover-opacity-50">
						<img src={logo} alt="logo" width="auto"/>
						</Link>
					</div>
				</div>
			</div>

			<div className="panel small">
				<div className="grid-x pt-60l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-patrick-hand font-xx-large font-weight-400 txt-555 text-center uppercase bg-fff b6-555 p-20l-10s">
							Find a place to
							<div className="pt-5"></div>
							work/study remotely
						</div>
					</div>
				</div>
			</div>
			
			<div className="panel small pt-60l-20s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell text-right pb-20">
						<Link to="/forgot_password" className="font-patrick-hand font-x-large font-weight-400">Forgot Password?</Link>
					</div>
					<div className="large-12 medium-12 small-12 cell text-center">
						<div id="sign_in_info_and_error" className="font-Roboto font-medium text-center ptb-10 hide"></div>
		
						<form onSubmit={loginSubmit}>
						<div className="grid-x">
							<div className="large-6 medium-6 small-12 cell text-left pr-5l-0s">
								<div className={loginInput.errorStyle.email}>{loginInput.errorList.email}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-envelope txt-555"></span></span>
									<input type="text" name="email" onChange={handleInput}  value={loginInput.email} placeholder="Email" />
								</div>
							</div>
							<div className="large-6 medium-6 small-12 cell text-left pl-5l-0s">
								<div className={loginInput.errorStyle.password}>{loginInput.errorList.password}</div>
								<div className="input-group">
									<span className="input-group-label"><span className="fas fa-lock txt-555"></span></span>
									<input type="password" name="password" onChange={handleInput} value={loginInput.password} placeholder="Password" />
								</div>
							</div>
							<div className="large-6 medium-6 small-5 cell text-left">
								<div className="hide-for-580px"><div className="pt-10"></div></div>
								<span className="font-patrick-hand font-x-large font-weight-400">No account? </span>
								<div className="show-for-580px"><div className="pt-2"></div></div>
								<Link to="/sign_up" className="font-patrick-hand font-x-large font-weight-400">Sign Up</Link>
							</div>
							<div className="large-6 medium-6 small-7 cell clearfix">
								<button type="submit" className="button width-125px-90px right">Sign In</button>
								{isLoading && 
								<span className="pr-10 right">
									<LoadingSpinner />
								</span>
								}
							</div>
						</div>
						</form>
						<div className="grid-x pt-20">
							<div className="large-12 medium-12 small-12 cell text-center">
							<Link to="/search" className="button large inverted width-100per"><img src={message_icon} width="25" alt="guest"/>&nbsp;&nbsp;&nbsp;<span className="font-x-large pt-5">Continue as Guest</span></Link>
							</div>
						</div>
		
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
