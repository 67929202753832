import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../../components/frontend/auth/AuthUtility';

import swal from 'sweetalert';

import home_icon from '../../assets/frontend/images/home_icon.png';
import search_icon from '../../assets/frontend/images/search_icon.png';
import location_icon from '../../assets/frontend/images/location_icon.png';
import bookmark_full_icon from '../../assets/frontend/images/bookmark_full_icon.png';
import plus_circle_icon from '../../assets/frontend/images/plus_circle_icon.png';
import account_icon from '../../assets/frontend/images/account_icon.png';

const OnCanvasNavigation = () => {
	
	//logout should be its own component
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);

	const logoutSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiDataLogout(response);
			} catch (error) {
			  handleApiErrorLogout(error);
			}
		};
	  
		fetchData();
	}
    function setApiDataLogout(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}

		setIsLoading(false);
	}
    function handleApiErrorLogout(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		setIsLoading(false);
		
		navHistory('/login');
    }

	return(
		<div className="sticky-bottom">
			<div className="sticky bg-fafafa bt1-ddd ptb-10l-5s">
				
				{/*
				<div className="panel large">
					<div className="grid-x">
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/home" className="button icon-sticky-bottom image-container no-underline">
							<img src={home_icon} alt="add icon" width="40" className="hide-for-small-only"/>
							<img src={home_icon} alt="add icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">Home</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/search" className="button icon-sticky-bottom image-container no-underline">
							<img src={search_icon} alt="search icon" width="40" className="hide-for-small-only"/>
							<img src={search_icon} alt="search icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">Search</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/check_ins" className="button icon-sticky-bottom image-container no-underline">
							<img src={location_icon} alt="location icon" width="40" className="hide-for-small-only"/>
							<img src={location_icon} alt="location icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">
								<span className="hide-for-small-only">Check-Ins</span>
								<span className="show-for-small-only">ChckIns</span>
							</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/saved_lists" className="button icon-sticky-bottom image-container no-underline">
							<img src={bookmark_full_icon} alt="bookmark icon" width="40" className="hide-for-small-only"/>
							<img src={bookmark_full_icon} alt="bookmark icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">Saved</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/add" className="button icon-sticky-bottom image-container no-underline">
							<img src={plus_circle_icon} alt="plus circle icon" width="40" className="hide-for-small-only"/>
							<img src={plus_circle_icon} alt="plus circle icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">Add</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/account" className="button icon-sticky-bottom image-container no-underline">
							<img src={account_icon} alt="account icon" width="40" className="hide-for-small-only"/>
							<img src={account_icon} alt="account icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5">Account</div>
							</Link>
						</div>
					</div>
				</div>
				*/}
					<div className="flex-container space-evenly">
						<div className="text-center">
							<Link to="/home" className="button icon-sticky-bottom image-container no-underline">
							<img src={home_icon} alt="add icon" width="40" className="hide-for-small-only"/>
							<img src={home_icon} alt="add icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Home</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Home</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Home</span>
							</div>
							</Link>
						</div>
						<div className="text-center">
							<Link to="/search" className="button icon-sticky-bottom image-container no-underline">
							<img src={search_icon} alt="search icon" width="40" className="hide-for-small-only"/>
							<img src={search_icon} alt="search icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Search</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Search</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Search</span>
							</div>
							</Link>
						</div>
						<div className="text-center">
							<Link to="/member/check_ins" className="button icon-sticky-bottom image-container no-underline">
							<img src={location_icon} alt="location icon" width="40" className="hide-for-small-only"/>
							<img src={location_icon} alt="location icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Check-Ins</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Chk-Ins</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Chk-Ins</span>
							</div>
							</Link>
						</div>
						<div className="text-center">
							<Link to="/member/saved_lists" className="button icon-sticky-bottom image-container no-underline">
							<img src={bookmark_full_icon} alt="bookmark icon" width="40" className="hide-for-small-only"/>
							<img src={bookmark_full_icon} alt="bookmark icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Saved</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Saved</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Saved</span>
							</div>
							</Link>
						</div>
						<div className="text-center">
							<Link to="/member/add" className="button icon-sticky-bottom image-container no-underline">
							<img src={plus_circle_icon} alt="plus circle icon" width="40" className="hide-for-small-only"/>
							<img src={plus_circle_icon} alt="plus circle icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Add</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Add</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Add</span>
							</div>
							</Link>
						</div>
						<div className="text-center">
							<Link to="/member/account" className="button icon-sticky-bottom image-container no-underline">
							<img src={account_icon} alt="account icon" width="40" className="hide-for-small-only"/>
							<img src={account_icon} alt="account icon" width="25" className="show-for-small-only"/>
							<div className="font-patrick-hand font-large font-weight-400 letter-spacing-0px pt-5 hide-for-small-only">Account</div>
							<div className="show-for-small-only">
								<span className="font-patrick-hand font-medium font-weight-400 letter-spacing-0px pt-5 hide-for-480px">Account</span>
								<span className="font-patrick-hand font-standard font-weight-400 letter-spacing-0px pt-3 show-for-480px">Acnt</span>
							</div>
							</Link>
						</div>
					</div>

			</div>
		</div>
	);
}

export default OnCanvasNavigation;