import React from 'react';
import {Link} from 'react-router-dom';

function About(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel large pt-20">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-xxl-large-mr font-weight-700 txt-333 uppercase bb1-333 pb-5">About</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default About;